import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../services/firebase';
import { YahooLeague } from '../types/yahoo';
import axios, { AxiosError } from 'axios';

interface WeeklyChallengeProps {
  yahooLeague: YahooLeague;
  yahooToken: string;
  refreshYahooToken: () => Promise<string>;
}

interface WeeklyChallengeData {
  name: string;
  description: string;
  week: number;
  position: string;
}

interface TeamResult {
  name: string;
  playerName: string;
  points: number;
}

interface ChallengeResult {
  team1: TeamResult;
  team2: TeamResult;
  winner: string;
  week: number;
}

const WeeklyChallenge: React.FC<WeeklyChallengeProps> = ({ yahooLeague, yahooToken, refreshYahooToken }) => {
  const [challenge, setChallenge] = useState<WeeklyChallengeData | null>(null);
  const [team1, setTeam1] = useState<string>('');
  const [team2, setTeam2] = useState<string>('');
  const [result, setResult] = useState<ChallengeResult | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchChallenge = async () => {
      const challengeDoc = await getDoc(doc(db, 'weeklyChallenge', 'current'));
      if (challengeDoc.exists()) {
        setChallenge(challengeDoc.data() as WeeklyChallengeData);
      }
    };

    fetchChallenge();
  }, []);

  const getTeamStats = async (teamId: string, position: string, week: number) => {
    let token = yahooToken;
    if (!token) {
      token = await refreshYahooToken();
    }

    const rosterUrl = `https://fantasysports.yahooapis.com/fantasy/v2/team/${yahooLeague.league_key}.t.${teamId}/roster/players/stats;type=week;week=${week}`;
    
    try {
      const response = await axios.post('/.netlify/functions/yahoo-api-proxy', {
        url: rosterUrl,
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      });

      if (response.data.error) {
        throw new Error(response.data.error);
      }

      // Parse the XML response
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response.data, "text/xml");
      const playerElements = xmlDoc.getElementsByTagName("player");

      for (let i = 0; i < playerElements.length; i++) {
        const playerElement = playerElements[i];
        const playerPosition = playerElement.getElementsByTagName("selected_position")[0].getElementsByTagName("position")[0].textContent;
        
        if (playerPosition === position) {
          const playerName = playerElement.getElementsByTagName("name")[0].getElementsByTagName("full")[0].textContent || "";
          const pointsElement = playerElement.getElementsByTagName("player_points")[0].getElementsByTagName("total")[0];
          const points = parseFloat(pointsElement ? pointsElement.textContent || "0" : "0");

          return {
            playerName,
            points
          };
        }
      }

      throw new Error(`No player found for position ${position}`);
    } catch (error) {
      console.error('Error fetching team stats:', error);
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response?.data && typeof axiosError.response.data === 'object' && 'error' in axiosError.response.data) {
          throw new Error(`Yahoo API Error: ${axiosError.response.data.error}`);
        } else {
          throw new Error(`Network error: ${axiosError.message}`);
        }
      } else if (error instanceof Error) {
        throw new Error(`Error: ${error.message}`);
      } else {
        throw new Error('An unknown error occurred');
      }
    }
  };

  const refreshResults = async () => {
    if (!team1 || !team2 || !challenge) {
      setError('Please select both teams');
      return;
    }

    setLoading(true);
    setError(null);

    try {
      const team1Data = await getTeamStats(team1, challenge.position, challenge.week);
      const team2Data = await getTeamStats(team2, challenge.position, challenge.week);

      const team1Result: TeamResult = {
        name: yahooLeague.teams.find(t => t.team_id === team1)?.name || '',
        playerName: team1Data.playerName,
        points: team1Data.points
      };

      const team2Result: TeamResult = {
        name: yahooLeague.teams.find(t => t.team_id === team2)?.name || '',
        playerName: team2Data.playerName,
        points: team2Data.points
      };

      const winner = team1Result.points > team2Result.points ? team1Result.name : team2Result.name;

      const newResult: ChallengeResult = {
        team1: team1Result,
        team2: team2Result,
        winner: winner,
        week: challenge.week
      };

      setResult(newResult);

      // Save result to Firebase
      await setDoc(doc(db, 'weeklyChallengeResults', `week${challenge.week}`), newResult);

    } catch (err) {
      setError('Error fetching team stats. Please try again.');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  if (!challenge) {
    return <div>Loading challenge...</div>;
  }

  return (
    <div className="bg-[#18f7b1] p-4 rounded-lg mt-4">
      <h2 className="text-3xl font-bold mb-4 text-black italic font-eau">WEEKLY CHALLENGE</h2>
      <div className="text-black text-base sm:text-xl font-source">
        <p className="mb-2"><strong>Week {challenge.week}:</strong> {challenge.name}</p>
        <p className="mb-4">{challenge.description}</p>
        <p className="mb-4"><strong>Position:</strong> {challenge.position}</p>
        <div className="flex flex-col sm:flex-row items-center justify-between mb-4">
          <select
            value={team1}
            onChange={(e) => setTeam1(e.target.value)}
            className="w-full sm:w-2/5 p-2 border rounded mb-2 sm:mb-0"
          >
            <option value="">Select Team 1</option>
            {yahooLeague.teams.map((team) => (
              <option key={team.team_id} value={team.team_id}>{team.name}</option>
            ))}
          </select>
          <span className="text-xl font-bold my-2 sm:my-0">VS</span>
          <select
            value={team2}
            onChange={(e) => setTeam2(e.target.value)}
            className="w-full sm:w-2/5 p-2 border rounded"
          >
            <option value="">Select Team 2</option>
            {yahooLeague.teams.map((team) => (
              <option key={team.team_id} value={team.team_id}>{team.name}</option>
            ))}
          </select>
        </div>
        <button
          className="bg-black hover:bg-[#333333] text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full sm:w-auto"
          onClick={refreshResults}
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Refresh Results'}
        </button>
        {error && <p className="text-red-500 mt-2">{error}</p>}
        {result && (
          <div className="mt-4">
            <h3 className="text-2xl font-bold mb-2">Results</h3>
            <p>{result.team1.name} ({result.team1.playerName}): {result.team1.points} points</p>
            <p>{result.team2.name} ({result.team2.playerName}): {result.team2.points} points</p>
            <p className="font-bold mt-2">Winner: {result.winner}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default WeeklyChallenge;